import './styles.css';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

function About() {
    return (
        <div className="About">
            <header>
                <Header />
            </header>
            <body>
                <div className="hero gradient-to-r from-primary to-secondary bg-base-200">
                    <div className="hero-content text-neutral-content">
                        <br></br>
                        <h1 className="mb-5 text-5xl font-bold items-center">Why I have a Homelab</h1>
                        <br></br>
                    </div>
                </div>
                <div>
                    <div className="bg-base-200 min-h-screen items-left main-content">
                        <br></br>
                        <br></br>
                        <h2 className="text-3xl font-bold items-center align-center">Skill Development & Training</h2>
                        <br></br>
                        <p className="items-left align-left text-left text-left">A homelab acts as a testbed or playground for contstant learning and development of IT & Cybersecurity skills. Professionals and amateurs alike can learn to deploy, configure, and use different operating systems (OS's), hypervisors, container systems, and more.</p>
                        <br></br>
                        <p className="items-left align-left text-left">Hands-on experience can be incredibly beneficial, and helps supplement theoretical knowledge. It also allows practitionsers to build up confidence in their skills/abilities, which can translate into effience and effective work in professional environments.</p>
                        <br></br>
                        <p className="items-left align-left text-left">Because of the above mentioned points, I feel like my homelab has taught me an incredible amount about enterprise architecture and cybersecurity. However, there is always more to learn.</p>
                        <br></br>
                        <br></br>
                        <h2 className="text-3xl font-bold items-center align-center">Cybersecurity Practice</h2>
                        <br></br>
                        <p className="items-left align-left text-left">My homelab has been an invaluable tool in teaching me more about cybersecurity. I've been able to learn alot about various security risks, and implement methods to mitigate the aforementioned risks. I've also learned a lot about Zero Trust Network Architecture (ZTNA) as a result, which I personally believe to be the future of cybersecurity.</p>
                        <br></br>
                        <p className="items-left align-left text-left">I'm constantly performing vulnerability assessments, and pentesting my network. It's a safe environement for me to perform ethical hacking, which in turn, teaches me how to prevent other attackers from accessing my network.</p>
                        <br></br>
                        <p className="items-left align-left text-left">This knowledge can directly translate into effective defense strategies in enterprise networks.</p>
                        <br></br>
                        <p className="items-left align-left text-left">To see my cybersecurity practice & research in action, you can learn more here [NOTE: REPLACE WITH LINK]</p>
                        <br></br>
                        <br></br>
                        <h2 className="text-3xl font-bold items-center align-center">Building & Breaking Systems</h2>
                        <br></br>
                        <p className="items-left align-left text-left">I'm a hands-on learner by nature. My homelab has allowed me to build systems from scratch, utilizing VM's to spin up as many servers, and computers as I need. I have a server cluster that can spin up a large number of VM's, which allows me to simulate entire enterprise networks.</p>
                        <br></br>
                        <p className="items-left align-left text-left">Breaking systems has taught me a lot about troubleshooting methods & tricks that I've been able to apply to real-world scenarios.</p>
                        <br></br>
                        <br></br>
                        <h2 className="text-3xl font-bold items-center align-center">Conclusion</h2>
                        <br></br>
                        <p className="items-left align-left text-left">A homelab is more than just a hobbyist's "plaything", it's a powerful tool for personal & professional growth. It's incredibly useful for building skills, testing changes in a controlled enviroment prior to use in production environments, and is a great way to test & refine skills in a controlled, risk-free environment.</p>
                        <br></br>
                        <p className="items-left align-left text-left">If you're passionate about technology, you should consider setting up your own IT homelab, and explore your own potential, and the potential of your lab.</p>
                        <br></br>
                        <br></br>
                    </div>
                </div>
            </body>
            <footer>
                <Footer />
            </footer>
        </div>
    );
}

export default About;