import React from 'react';
import './styles.css';

function Hero03() {
  return(
    <div className="Hero03 flex items-center hero-padding-main">
      <div className="container mx-auto text-center">
        <div className="hero-content">
          <h1 className="text-5xl font-bold mb-6">Lessons I've learned.</h1>
          <p className="py-6">My Homelab has taught me valuable lessons about technology, problem-solving, and patience. Here are a few:</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="card w-96 bg-base-200 shadow-xl mb-6 mt-6">
            <div className="card-body">
              <h2 className="card-title">Don't be afraid to break things (but not too much)</h2>
              <p>It's okay to make mistakes while experimenting. Learning from failure is a fundamental skill in IT.</p>
            </div>
          </div>
          <div className="card w-96 bg-base-200 shadow-xl mb-6 mt-6">
            <div className="card-body">
              <h2 className="card-title">Planning is Key</h2>
              <p>Before diving in, you need to have a clear plan of what you want to accomplish. This helps you focus on your goals and make better decisions.</p>
            </div>
          </div>
          <div className="card w-96 bg-base-200 shadow-xl mb-6 mt-6">
            <div className="card-body">
              <h2 className="card-title">Stay on top of Updates</h2>
              <p>Technology evolves quickly, so it’s essential to stay informed and up to date with the latest software, hardware, and security updates.</p>
            </div>
          </div>
          <div className="card w-96 bg-base-200 shadow-xl mb-6 mt-6">
            <div className="card-body">
              <h2 className="card-title">Patience Pays Off</h2>
              <p>Building a Homelab takes time and patience. Don’t rush things and make sure you take the time to enjoy the process. It’s always a “work in progress”.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero03;