// Header.js 
// MTECH Gen7 Site
// Kain M. Snyder

import { Link } from 'react-router-dom';
import './styles.css';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, QuestionMarkCircleIcon, TicketIcon, EnvelopeIcon } from '@heroicons/react/20/solid'

function Header() {

    const solutions = [
        { name: 'Help Desk Portal', description: 'Visit the Help Desk Portal', href: 'https://help.malachi.technology/index.php', icon: QuestionMarkCircleIcon },
        { name: 'Submit a Ticket', description: 'Submit a Support Ticket', href: 'https://help.malachi.technology/index.php?a=add', icon: TicketIcon },
    ]

    const callsToAction = [
        { name: 'Email Us', href: 'mailto:help@malachi.technology', icon: EnvelopeIcon },
        { name: 'Call Us', href:'tel:+13853841650', icon: PhoneIcon },
    ]

    return (
        <div className="font-figtree">
            <div className="alert alert-warning p-2 pb-2" style={{ backgroundColor: '#f9e2af' }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                <span>Warning: This site is the development site. To return to the main site, press the info button.</span>
                <div>
                    <button className="btn btn-sm" href="https://malachi.technology">Info</button>
                </div>
            </div>
            <header className="bg-base-300 shadow-md flex">
                <div className="flex px-6 w-1/2 items-center font-semibold md:w-1/5 md:px-1 md:flex md:items-center md:justify-start">
                    <img src="/Gen6-512.png" alt="Logo" className="mr-2" style={{ maxWidth: '70px', maxHeight: '70px' }} />
                    <Link className="text-2xl text-primary blue font-bold" to="/">Malachi Technologies LLC</Link>
				</div>
				<div className="navbar-end justify-center items-center">
                    <div className="flex items-center justify-center join">
                        <Link className="btn btn-ghost btn-sm join-item" to="/blog">Blog</Link>
                        <Popover className="relative">
                            <PopoverButton className="btn btn-ghost btn-sm join-item">
                                <span>Help Desk</span>
                                <ChevronDownIcon aria-hidden="true" className="h-5 w-5"/>
                            </PopoverButton>
                            <PopoverPanel transition className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
                                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-base-300 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        {solutions.map((item) => (
                                            <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                                <div className="mt01 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                                                </div>
                                                <div>
                                                    <a href={item.href} className="font-semibold text-gray-900">
                                                        {item.name}
                                                        <span className="absolute inset-0" />
                                                    </a>
                                                    <p className="mt-1 text-gray-600">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                        {callsToAction.map((item) => (
                                            <a
                                            key={item.name}
                                            href={item.href}
                                            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                                            >
                                                <item.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </PopoverPanel>
                        </Popover>
						<a className="btn btn-ghost btn-sm join-item" href="https://uptime.malachi.technology">Service Status</a>
						<Link className="btn btn-ghost btn-sm join-item" to="/about">About</Link>
                    </div>
                </div>
				<div className="w-1/5 items-center justify-evenly font-semibold md:flex justify-content-end">
					<button className="btn btn-ghost">Login</button>
					<button className="btn-animated">Sign Up</button>
				</div>
            </header>
        </div>
    );
}

export default Header;
