import './App.css';
import { Link } from 'react-router-dom';
import Header2 from './components/Header2.js';
import Footer from './components/Footer.js';
import Hero01 from './AppJS/Hero01.js';
import Hero02 from './AppJS/Hero02.js';
import Hero03 from './AppJS/Hero03.js';

function App() {
  return (
    <div className="App font-figtree">
      <header>
        <Header2 />
      </header>
      <body>
        <div className="hero min-h-screen" style={{ backgroundImage: 'url(/mtech-bg.png)' }}>
          <div className="hero-overlay bg-opacity-10 blur"></div>
          <div className="hero-content text-left align-left text-neutral-content text-blue">
            <div className="max-w-md">
              <h1 className="mb-5 text-6xl font-bold">IT Partners you can Trust.</h1>
              <p className="mb-5 text-2xl">Whether it's Cloud Computing, IT Consulting, or even device repair, Malachi Technologies will always strive to bring efficient solutions to our customers.</p>
              <Link className="btn btn-primary" to="/about">Learn More</Link>
            </div>
          </div>
        </div>
        <div>
          <Hero01 />
        </div>
        <div>
          <Hero02 />
        </div>
        <div>
          <Hero03 />
        </div>
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
