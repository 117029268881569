import React from 'react';
import './styles.css';

function Hero02() {
  return(
    <div className="Hero02 py-15 hero-padding-main">
      <div className="hero h-auto bg-base-300">
        <div className="hero-content items-center text-center text-neutral-content">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">Building My Homelab</h1>
            <p className="mb-5">I built my Homelab piece by piece, constantly exploring new technologies and trying out different configurations. I started small, with a basic setup of two dell server towers, and eventually expanded to a more complex system, including an HP Proliant Server, Cisco Switches, and more.</p>
            <button className="btn btn-primary">See More</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero02;