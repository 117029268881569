import Header from './components/Header.js';
import Footer from './components/Footer.js';

const posts = [
	{
		id: 1,
		title: 'JILL Project Findings',
		href: 'https://malachi.technology/blog/projectjill/projectfindings',
		description: 'A review of the honeypot research findings.',
		date: 'Dec 24th, 2022',
		datetime: '2022-12-24',
		category: {title: 'JILL Project', href: '#'},
		author: {
			name: 'Malachi S.',
			role: 'Founder',
			href: '#',
			imageUrl: './Gen6-512.png',
		},
	},
]

export default function Blog() {
	return (
		<div>
			<div>
				<Header />
			</div>
			<div>
				<div className="bg-base-100 py-24 sm:py-32">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<div className="mx-auto max-w-2xl lg:mx-0">
							<h2 className="text-3xl font-bold tracking-tight text-blue sm:text-4xl">Blog & Latest Articles</h2>
							<p className="mt-2 text-lg leading-8 text-neutral-content">Technology has had a major impact on business, with many companies relying on computers and other devices to improve efficiency and productivity. It has also facilitated communication and collaboration. Below, you can find previews of recently posted blog articles.</p>
						</div>
					</div>
					<div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 items-center">
						{posts.map((post) => (
							<article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
								<div className="flex items-center gap-x-4 text-xs">
									<time dateTime={post.datetime} className="text-neutral-content">
										{post.date}
									</time>
									<a href={post.category.href} className="relative z-10 rounded-full bg-base-200 px-3 py-1.5 font-medium hover:bg-base-100">{post.category.title}</a>
								</div>
								<div className="group relative">
									<h3 className="mt-3 text-lg font-semibold leading-6 text-neutral-content">
										<a href={post.href}><span className="absolute inset-0" />{post.title}</a>
									</h3>
									<p className="mt-5 line-clamp-3 text-sm leading-6 text-neutral-content">{post.description}</p>
								</div>
								<div className="relative mt-8 flex items-center gap-x-4">
									<img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-base-100" />
									<div className="text-sm leading-6">
										<p className="font-semibold text-neutral-content">
											<a href={post.author.href}><span className="absolute inset-0" />{post.author.name}</a>
										</p>
										<p className="text-neutral-content">{post.author.role}</p>
									</div>
								</div>
							</article>
						))}
					</div>
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	)
}