import React from 'react';
import './styles.css';

function Hero01() {
  return(
    <div className="Hero01 flex items-center hero-padding-main">
      <div className="container mx-auto">
        <div className="hero-content flex-col lg:flex-row">
          <h1 className="text-5xl font-bold">Managed Cloud Solutions</h1>
          <p className="py-6">Whether you're a new entrepreneur starting </p>
        </div>
        <div className="flex flex-wrap justify-between">
          <div className="card w-96 bg-base-200 shadow-xl mt-6">
            <div className="card-body">
              <h2 className="card-title">Learning New Skills</h2>
              <p>My homelab is my sandbox for exploring new technologies and pushing my skills to the limit.</p>
            </div>
          </div>
          <div className="card w-96 bg-base-200 shadow-xl mt-6">
            <div className="card-body">
              <h2 className="card-title">Exploring New Ideas</h2>
              <p>I love trying out new tools, concepts, and ideas. I can put my creativity to the test and see what I come up with!</p>
            </div>
          </div>
          <div className="card w-96 bg-base-200 shadow-xl mt-6">
            <div className="card-body">
              <h2 className="card-title">Building Experience</h2>
              <p>Tinkering in my homelab helps me gain invaluable insights into how the world of IT works. Some of this experience directly impacts my career.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero01;